<template>
  <div class="content-booking">
    <AppHeader title="实验预约-日期选择"/>
    <Panel class="cal">
      <div slot="title" class="cal-title">
        <div class="cal-day-selector">
          <div class="cal-day-op"><i class="iconfont icon-prev" @click="computeWeeks(-1)"></i></div>
          <div :class="`cal-day-holder${idx===weekOfDay-1?' active':''}`" v-for="(day,idx) of curWeekDays"
               @click="setCurDay(day)">
            <div class="cal-day-chinese">{{ dayTitles[idx] }}</div>
            <div class="cal-day-num">{{ day.format('DD') }}</div>
          </div>
          <div class="cal-day-op"><i class="iconfont icon-next" @click="computeWeeks(1)"></i></div>
        </div>
        <div class="cal-day-full">{{ curDay.format('YYYY年MM月DD日') }} 星期{{ dayTitles[weekOfDay - 1] }}</div>
      </div>
      <div slot="content" style="overflow: auto">
        <table class="cal-table" >
          <tr class="cal-line cal-header">
            <template v-if="cellsPerLine>1">
              <td id="lineTd" class="cal-line-title">
                <div class="title-hour">时</div>
                <div class="title-minute">分</div>
              </td>
              <td class="cal-line-period" v-for="i in cellsPerLine">{{ warpZero((i - 1) * bookingStep) }} -
                {{ warpZero(i * bookingStep) }}
              </td>
            </template>
            <template v-else>
              <td class="cal-line-period">时间段</td>
            </template>
          </tr>
          <tr class="cal-line" v-for="rowNo of lineCnt">
            <td class="cal-line-title" v-if="cellsPerLine>1">{{ labelHour(rowNo - 1) }}:00</td>
            <td :class="`cal-line-period ${cell.css()}`" v-for="(cell, idx) in cells[rowNo-1]"
                @click="toastOrSelect(cell,rowNo-1,idx)">
              <div :class="cellsPerLine>1?'iconfont icon-seat cal-icon':'simple-time'">
                {{ cellsPerLine > 1 ? '' : display(rowNo - 1) }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </Panel>
    <Panel class="confirm-panel" v-if="bookings.length>0">
      <div slot="title">
        日期确认
      </div>
      <div slot="content">
        <div class="content">
          <div class="form-lines">
            <div class="form-line" v-for="(startEnds,idx) in bookings">
              {{ dateFormat(startEnds[0]) }} 至 {{ dateFormat(startEnds[1]) }}
              <div class="btn btn-small red" @click="deleteStartEnds(startEnds)">删除</div>
            </div>
          </div>
          <div class="btn green mt10 confirm-btn" @click="popupVisible=true">开始预定</div>
        </div>
      </div>
    </Panel>

    <mt-popup
        v-model="popupVisible"
        position="bottom">
      <div class="popup-title">预约信息</div>
      <div style="border-bottom: 1px solid #ddd; padding: 20px">
        <div class="flex-dir-row">
          <div class="popup-form-label">预约描述</div>
          <textarea class="flex-item-auto" rows="10" placeholder="预约描述" v-model="remark"></textarea>
        </div>
      </div>
      <div class="mint-msgbox-btns">
        <button class="mint-msgbox-btn mint-msgbox-cancel" @click="popupVisible=false;remark=''">取消</button>
        <button class="mint-msgbox-btn mint-msgbox-confirm" @click="confirmBooking">确定</button>
      </div>
    </mt-popup>
  </div>
</template>
<style lang="scss">
#lineTd {
  //position: relative;
  //    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="0" y1="0" x2="100%" y2="100%" stroke="rgba(0, 0, 0, 0.05)" stroke-width="1"/></svg>
  background: #3b5998 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxsaW5lIHgxPSIwIiB5MT0iMCIgeDI9IjEwMCUiIHkyPSIxMDAlIiBzdHJva2U9InJnYmEoMCwgMCwgMCwgMC4wNSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==) no-repeat 100% center;
}

.title-hour, .title-minute {
  font-size: 12px;
  position: absolute;
  border: none;
}

.title-hour {
  left: 10px;
  bottom: 0;
}

.title-minute {
  right: 10px;
  top: 0;
}

.confirm-panel {
  background: #fff !important;
  margin-left: -20px;
  margin-right: -20px;
  .panel-title {
    text-align: center;
    border: 0;
    font-size: 15px;
    background: inherit;
    border-bottom: 1px solid #ddd;
    margin: 0;
  }
}

.cal-title {
  font-size: 18px;
  font-weight: 800;
  text-align: center !important;
}

.cal-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  td {
    border: 1px solid rgba(0, 0, 0, 0.05);
    height: 20px;
    //line-height: 30px;
  }
}





th {
  position: sticky;
  top: 0px;
}

td:first-child {
  position: sticky;
  left: 0px;
}

th:first-child {
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 10;
}




.cal-line {
  font-size: 16px;
  width: 100%;
}

.cal-line-title {
  padding: 0 15px;
  width: 80px;
}

.cal-header .cal-line-period, .cal-line-title {
  background-color: #3b5998;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.cal-line-period {
  text-align: center;
  padding: 5px;
  background-color: #fff;

  .iconfont {
    color: #fff;
    border: 0;
    font-size: 16px;
    font-weight: bold;
  }

  &.other-booked {
    background-color: #7d7d7d !important;

    .simple-time {
      color: #fff;
    }
  }

  &.rule-booked {
    background-color: #7C8ACA !important;

    .simple-time {
      color: #fff;
    }
  }

  &.self-booked {
    background-color: #00a65a !important;

    .simple-time {
      color: #fff;
    }
  }

  &.self-finished {
    background-color: #7d7d7d !important;

    .simple-time {
      color: #fff;
    }
  }

  &.self-selected {
    background-color: #967ADC !important;

    .simple-time {
      color: #fff;
    }
  }
}

.cal-title {
  margin: 0 20px;
}

.cal-day-op {
  width: 30px;
  display: flex;
  flex: 1;
  align-items: center;

  .iconfont {
    font-size: 12px;
    color: RGBA(155, 155, 155, 1.00);
  }

  &:first-child {
    padding-left: 10px;
    justify-content: left;
  }

  &:last-child {
    padding-right: 10px;
    justify-content: flex-end;
  }
}

.cal-day-now {
  flex: 1 1 auto;
}


.cal-icon {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.content-booking {
  padding: 0 20px !important;
  display: flex;
  flex-direction: column;
  margin: 0;
  background: linear-gradient(#31B8D3, #1682A7);

  .panel-content {
    margin: 20px 0;
    border-radius: 5px;
    overflow: hidden;
  }

  .panel-title {
    height: inherit;
    background: #fff;
    line-height: inherit;
  }
}

.cal-day-holder {
  width: 42px;
  height: 64px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  .cal-day-chinese {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
    color: #999;
  }

  .cal-day-num {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }

  &.active {
    background: #415997;

    .cal-day-chinese, .cal-day-num {
      color: #fff;
    }

    .cal-day-num {
      font-weight: bold;
    }
  }
}

.cal-day-selector {
  padding-top: 18px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.cal-day-full {
  font-size: 16px;
  font-weight: 400;
  color: #415997;
  margin: 10px 0;
}

.confirm-btn {
  margin-bottom: 20px;
}

</style>
<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {Toast, MessageBox} from 'mint-ui';

class SelectedCell {

  constructor(vm, day, i, j) {
    this.vm = vm
    this.day = day;
    this.i = i;
    this.j = j;
    this.timeGap = this.vm.bookingStep * 60 * 1000
  }

  get key() {
    return cellKey(this.day, this.i, this.j)
  }

  beginTimeStamp() {
    const cfg = this.vm.equipment.bookingCfg
    const beginArr = cfg.beginAt.split(":")
    const startMinutes = parseInt(beginArr[0]) * 60 + parseInt(beginArr[1])
    return this.day + startMinutes * 60 * 1000 + (this.i * this.vm.cellsPerLine + this.j) * this.timeGap
  }

  endTimeStamp() {
    return this.beginTimeStamp() + this.timeGap
  }

  isConnected(cell) {
    return Math.abs(cell.beginTimeStamp() - this.beginTimeStamp()) === this.timeGap
  }
}

function cellKey(day, i, j) {
  return day + '-' + i + '-' + j
}

function cellClass() {
  let css = this.who || '';
  if (this.status) {
    css += '-' + this.status
  }
  if (this.disabled) {
    css += ' disabled'
  }
  return css
}

let defaultCell = function (disable) {
  return {who: '', status: '', title: '', disabled: disable}
}

export default {
  components: {
    Panel,
    AppHeader
  },
  data() {
    return {
      curDay: null,
      curWeek: null,
      curWeekDays: [],
      weekOfDay: 0,
      dayTitles: ['一', '二', '三', '四', '五', '六', '日'],
      selectedCells: [],
      cells: [],
      equipment: null,
      cellsPerLine: 4,
      //default value {step:15, beginAt:'00:00', endAt:'24:00'}
      lineCnt: 24,
      bookingStep: 15,
      maxStepCnt: -1,
      beginCell: [0, 0],
      endCell: [23, 3],
      myCells: 0,
      inIframe: false,
      hourRange: [0, 23],
      popupVisible: false,
      remark: ''
    }
  },
  methods: {
    warpZero(num) {
      if (num < 10) {
        return '0' + num
      }
      return '' + num
    },
    initCells() {
      let cells = [];
      for (let i = 0; i < this.lineCnt; i++) {
        cells[i] = []
        for (let j = 0; j < this.cellsPerLine; j++) {
          let disabled = false
          //之前的要disable
          if (i * 10 + j < this.beginCell[0] * 10 + this.beginCell[1]) {
            disabled = true
          }
          //之后的也要disable
          if (i * 10 + j > this.endCell[0] * 10 + this.endCell[1]) {
            disabled = true
          }
          let cell = defaultCell(disabled)
          cell.css = cellClass
          cells[i].push(cell)
        }
      }
      return cells
    },
    formatNum(num) {
      return num < 10 ? "0" + num : num
    },
    computeWeeks(weeks) {
      this.curWeekDays = []
      this.cells = this.initCells()
      const now = this.$moment().startOf('day')
      this.curDay = this.$moment(this.curDay ? this.curDay : now).add(+weeks * 7, "days")
      this.weekOfDay = this.$moment(this.curDay).format('E')
      //    7
      //  -6 -5 -4 -3 -2 -1 0
      for (let i = 1 - this.weekOfDay; i <= 7 - this.weekOfDay; i++) {
        this.curWeekDays.push(this.$moment(this.curDay).add(i, "days"))
      }
      this.markEvents()
      this.markSelectedCells()
    },
    markSelectedCells() {
      for (const cell of this.selectedCells) {
        if (cell.day === this.curDay.valueOf()) {
          this.toggleSelected(this.cells[cell.i][cell.j])
        }
      }
    },
    toggleSelected(cell) {
      if (cell.status === 'selected') {
        cell.who = ''
        cell.status = ''
      } else {
        cell.who = 'self'
        cell.status = 'selected'
      }
    },
    markEvents() {
      const _that = this
      const data = new FormData();
      data.append("start", '' + _that.curDay.valueOf())
      data.append("end", '' + _that.$moment(_that.curDay).add(24, "hours").valueOf())
      data.append("equipmentId", '' + _that.$route.query.equipmentId)
      _that.$http.post("/portal/booking/events", data).then(events => {
        _that.myCells = 0
        for (let ev of events) {
          _that.markCell(ev)
        }
      })
    },
    markCell(ev) {
      // console.log(`mark cell ${JSON.stringify(ev)}`)
      let myCells = 0
      const cfg = this.equipment.bookingCfg
      const beginArr = cfg.beginAt.split(":")
      const endArr = cfg.endAt.split(":")
      const startMinutes = parseInt(beginArr[0]) * 60 + parseInt(beginArr[1])
      const endMinutes = parseInt(endArr[0]) * 60 + parseInt(endArr[1])

      const {start, end, title, id, my, backgroundColor, borderColor} = ev
      const curDayStart = this.$moment(this.curDay).add(startMinutes, "minutes")
      const curDayEnd = this.$moment(this.curDay).add(endMinutes, "minutes")
      let matchCurDay = false
      let markStart, markEnd
      if (start <= curDayStart && curDayStart <= end) {
        //[curDayStart, end]
        matchCurDay = true
        markStart = curDayStart
        markEnd = end
      } else if (curDayStart <= start && curDayEnd <= end) {
        //[begin, curDayEnd]
        matchCurDay = true
        markStart = start
        markEnd = curDayEnd
      } else if (curDayStart <= start && curDayEnd >= end) {
        //[begin, end]
        matchCurDay = true
        markStart = start
        markEnd = end
      }
      if (matchCurDay) {
        const ms = this.$moment(markStart)
        const me = this.$moment(markEnd)



        for (let i = 0; i < this.lineCnt; i++) {
          const line = this.cells[i]
          for (let j = 0; j < this.cellsPerLine; j++) {
            //分
            const test = this.$moment(curDayStart).add((this.cellsPerLine * i + j + 1) * this.bookingStep - 1, "minutes")
            if (test.isBetween(ms, me)) {
              //#d2d6de : 其他人预定
              //#d5d5d5 : 自己已做过
              //#e9f4f5 或 #eff3ff: 自己未做过
              //#7C8ACA : 规则占位
              line[j].title = title

              switch (backgroundColor) {
                case '#d2d6de':
                  line[j].who = 'other'
                  line[j].status = 'booked'
                  break;
                case '#d5d5d5':
                  myCells++
                  line[j].who = 'self'
                  line[j].status = 'finished'
                  break;
                case '#e9f4f5':
                  myCells++
                  line[j].who = 'self'
                  line[j].status = 'booked'
                  break;
                case '#eff3ff':
                  myCells++
                  line[j].who = 'self'
                  line[j].status = 'booked'
                  break;
                case '#7C8ACA':
                  line[j].who = 'rule'
                  line[j].status = 'booked'
                  break;
              }
            }
          }
        }
      }
      this.myCells += myCells
    },
    toastOrSelect(cell, i, j) {
      if (cell.status === 'booked' || cell.status === 'finished') {
        Toast(cell.title)
      } else {
        const day = this.curDay.valueOf()
        const add = cell.status !== 'selected'

        const cfg = this.equipment.bookingCfg
        const beginArr = cfg.beginAt.split(":")
        const startMinutes = parseInt(beginArr[0]) * 60 + parseInt(beginArr[1])
        if (!this.$moment(this.curDay).add(startMinutes, "minutes").add((this.cellsPerLine * i + j + 1) * this.bookingStep + 1, "minutes").isAfter(this.$moment())) {
          Toast("无法选择一个过去的时间")
          return
        }
        let toggleFlag = true
        if (add) {
          toggleFlag = this.addSelectedCell(day, i, j)
        } else {
          this.removeSelectedCell(day, i, j)
        }
        if (toggleFlag) {
          this.toggleSelected(this.cells[i][j])
        }
      }
    },
    countDayCell() {
      let dayCnt = 0
      for (const cell of this.selectedCells) {
        if (cell.day === this.curDay.valueOf()) {
          dayCnt++
        }
      }
      return dayCnt + this.myCells
    },
    addSelectedCell(day, i, j) {
      if (this.maxStepCnt <=0 || this.countDayCell() < this.maxStepCnt) {
        this.selectedCells.push(new SelectedCell(this, day, i, j))
        return true
      } else {
        Toast(`每人每天最多预约${this.maxStepCnt}个时间段`)
        return false
      }
    },
    removeSelectedCell(day, i, j) {
      const ck = cellKey(day, i, j)
      for (let k = 0; k < this.selectedCells.length; k++) {
        if (this.selectedCells[k].key === ck) {
          this.selectedCells.splice(k, 1)
          break
        }
      }
    },
    dateFormat(timestamp) {
      return this.$moment(timestamp).format('YY-MM-DD HH:mm')
    },
    getStartEnd(selectedBookings) {
      const bs = selectedBookings[0].beginTimeStamp()
      const es = selectedBookings[selectedBookings.length - 1].endTimeStamp()
      return [bs, es]
    },
    deleteStartEnds(startEnds) {
      for (let i = this.selectedCells.length - 1; i >= 0; i--) {
        const selectedCell = this.selectedCells[i]
        if (selectedCell.beginTimeStamp() >= startEnds[0] && selectedCell.endTimeStamp() <= startEnds[1]) {
          this.selectedCells.splice(i, 1)
          if (selectedCell.day === this.curDay.valueOf()) {
            this.toggleSelected(this.cells[selectedCell.i][selectedCell.j])
          }
        }
      }
    },
    confirmBooking() {

      const _that = this
      const cfg = this.equipment.bookingCfg
      this.popupVisible = true
      let now = _that.$moment().valueOf()
      let confirmMsg = null
      if(_that.bookings.length>1){
        MessageBox('错误', '请选择连续的预约时段');
      }else {
        for (let i = 0; i < _that.bookings.length; i++) {
          const bk = _that.bookings[i]
          const diff = bk[0] - now
          if (diff < 0) {
            MessageBox('错误', '预约开始时间不能小于当前时间');
            break
          } else if (diff < cfg.cancelAhead * 60000) {
            if (diff < cfg.enterAhead * 60000) {
              confirmMsg = `距离实验开始不到${cfg.enterAhead}分钟，门禁系统可能无法生效。且无法取消预约，是否确认继续预约?`
            } else {
              confirmMsg = `距离实验开始不到${cfg.cancelAhead}分钟，确认预约后没有取消的机会，是否确认继续预约？`
            }
          }
        }
        if (confirmMsg) {
          MessageBox.confirm(confirmMsg).then(action => {
            _that.startBooking()
          })
        } else {
          _that.startBooking()
        }
      }

      /*MessageBox.confirm('确定预约所选时间段?').then(action => {

      });*/
    },
    startBooking() {
      let allBookings = []
      const _that = this
      if (_that.bookings && _that.bookings.length > 0) {
        for (const bk of _that.bookings) {
          const data = new FormData();
          data.append("beginAt", `${bk[0]}`)
          data.append("endAt", `${bk[1]}`)
          data.append("equipmentId", '' + _that.$route.query.equipmentId)
          data.append("remark", _that.remark)
          allBookings.push(_that.$http.post('/portal/booking/save', data, {autoToast: false}))
        }
        Promise.all(allBookings).then(results => {
          let errorMsgs = []
          for (let i = 0; i < results.length; i++) {
            let result = results[i]
            if (!result.success) {
              errorMsgs.push(result.msg)
            }
          }
          _that.selectedCells = []
          _that.computeWeeks(0)

          if (errorMsgs.length > 0) {
            MessageBox.alert(errorMsgs.join('<br>')).then(action => {
              if (errorMsgs.length < allBookings.length) {
                this.toBookings()
              }
            });
          } else {
            this.toBookings()
          }
        })
      }
    },
    toBookings() {
      if (this.inIframe) {
        // window.parent.location.href = '/ucenter/equip/bookingDetailList'
      } else {
        this.$router.push({path: '/bookings'})
      }
    },
    displayMinutes(minutes) {
      const minLeft = minutes % 60
      return this.formatNum(Math.floor(minutes / 60)) + ":" + this.formatNum(minLeft)
    },
    labelHour(rowNum) {
      return this.formatNum(this.hourRange[rowNum])
    },
    display(rowNum) {
      const cfg = this.equipment.bookingCfg
      const beginArr = cfg.beginAt.split(":")
      const startMinutes = parseInt(beginArr[0]) * 60 + parseInt(beginArr[1])
      const beginStr = this.displayMinutes(startMinutes + rowNum * cfg.step)
      const endStr = this.displayMinutes(startMinutes + (rowNum + 1) * cfg.step)
      return beginStr + " 至 " + endStr
    },
    range(start, end) {
      return [...Array(1 + end - start).keys()].map(v => start + v)
    },
    setCurDay(curDay) {
      this.cells = this.initCells()
      this.curDay = curDay
      this.weekOfDay = this.$moment(this.curDay).format('E')
      this.markEvents()
      this.markSelectedCells()
    }
  },
  mounted() {
    this.inIframe = window.top !== window.self
    const that = this
    this.$http.get(`/api/equipment/info?equipmentId=${this.$route.query.equipmentId}`).then(resp => {
      that.equipment = resp.equipment
      const cfg = that.equipment.bookingCfg
      if (cfg) {
        that.bookingStep = cfg.step
        that.maxStepCnt = cfg.maxStepCnt
        const beginArr = cfg.beginAt.split(":")
        const endArr = cfg.endAt.split(":")
        that.cellsPerLine = that.bookingStep <= 60
        && 60 % that.bookingStep === 0
        && parseInt(beginArr[1]) % that.bookingStep === 0
        && parseInt(endArr[1]) % that.bookingStep === 0 ? 60 / that.bookingStep : 1
        // 01:00 - 01:15
        if (that.cellsPerLine > 1) {
          const beginCellY = parseInt(beginArr[1]) / that.bookingStep
          that.beginCell = [0, beginCellY]
          that.lineCnt = parseInt(endArr[0]) - parseInt(beginArr[0]) + (parseInt(endArr[1]) > 0 ? 1 : 0)
          const endCellY = parseInt(endArr[1]) / that.bookingStep - 1
          that.endCell = [that.lineCnt - 1, endCellY]
          that.hourRange = that.range(parseInt(beginArr[0]), parseInt(endArr[0]))
        } else {
          that.beginCell = [0, 0]
          that.lineCnt = Math.floor(((parseInt(endArr[0]) - parseInt(beginArr[0])) * 60 + (parseInt(endArr[1]) - parseInt(beginArr[1]))) / that.bookingStep)
          that.endCell = [that.lineCnt - 1, 0]
        }
      }
      that.computeWeeks(0)
    })
  },
  computed: {
    bookings: function () {
      this.selectedCells.sort(function (a, b) {
        return (a.day - b.day) + (a.i - b.i) * 60 + (a.j - b.j)
      })
      let _bookings = []
      let prevCell
      for (const cell of this.selectedCells) {
        if (prevCell && prevCell.isConnected(cell)) {
          _bookings[_bookings.length - 1].push(cell)
        } else {
          let b_cells = []
          _bookings.push(b_cells)
          b_cells.push(cell)
        }
        prevCell = cell
      }
      let startEnds = []
      for (const cells of _bookings) {
        startEnds.push(this.getStartEnd(cells))
      }
      return startEnds
    }
  }
}
</script>
